<template>
  <div>
    <h1>INSCRIÇÃO PARA EVENTOS</h1>
    <h4>IASD CONFRESA-MT</h4>
  </div>
</template>

<script>
export default {
  name : "CTitle"
}
</script>

<style scoped>
  div {
   display: block;
   padding: 30px 10px;
   margin-top: 40px;
  }
  h1, h4{
    display: block;
    text-align: center;
    color: rgb(241, 241, 241);
    text-shadow: 4px 4px 7px #02182b;
  }
</style>