<template>
  <div id="box">
    <slot></slot>
  </div>
  <span>Feito com ❤️ por Lucas Messias.</span>
</template>

<script>
  export default {
    name : "CBox"
  }
</script>

<style scoped>
  #box {
    display: block;
    padding: 20px 10px;
    max-width: 600px;
    margin:0 auto;
    border-radius: 7px;
    background-color: #f7f7f7;
    color: #1d1d1d;
    box-shadow: 3px 3px 4px -2px #021627;
    border:2px solid #0d385d;
  }
  span {
    display: block;
    padding: 20px 0 10px 0;
    font-size: 12px;
    text-align: center;
    margin-bottom: 50px;
    color: #fff;
    text-shadow: 2px 2px 2px #021729;
  }
</style>