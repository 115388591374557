<template>
  <div id="box-background">
    <span class="loader"></span>
  </div>
</template>

<style scoped>
  div#box-background{
    position: fixed;
    width: 100%;
    top:0;
    bottom:0;
    left:0;
    right:0;
    display: block;
    /*background-color: rgba(0, 0, 0, 0.73);*/
  }
  .loader {
    width: 48px;
    height: 48px;
  }
  .loader::before , .loader::after{
    content: '';
    transform: translate(-50% , -50%);
    left: 50%;
    top: 50%;
    position: absolute;
    width: 48em;
    height: 48em;
    background-image:
      radial-gradient(circle 10px, #FFF 100%, transparent 0),
      radial-gradient(circle 10px, #FFF 100%, transparent 0),
      radial-gradient(circle 10px, #FFF 100%, transparent 0),
      radial-gradient(circle 10px, #FFF 100%, transparent 0),
      radial-gradient(circle 10px, #FFF 100%, transparent 0),
      radial-gradient(circle 10px, #FFF 100%, transparent 0),
      radial-gradient(circle 10px, #FFF 100%, transparent 0),
      radial-gradient(circle 10px, #FFF 100%, transparent 0);
    background-position: 0em -18em, 0em 18em, 18em 0em, -18em 0em,
                        13em -13em, -13em -13em, 13em 13em, -13em 13em;
      background-repeat: no-repeat;
    font-size: 0.5px;
    border-radius: 50%;
    animation: blast 1s ease-in infinite;
  }
  .loader::after {
    font-size: 1px;
    background: #fff;
    animation: bounce 1s ease-in infinite;
  }

  @keyframes bounce {
    0% , 100%{ font-size: 0.75px }
    50% { font-size: 1.5px }
  }
  @keyframes blast {
    0% , 40% {
      font-size: 0.5px;
    }
    70% {
      opacity: 1;
      font-size: 4px;
    }
    100% {
      font-size: 6px;
      opacity: 0;
    }
  }
</style>