const mascaraTelefone = (valor) => {
  let value = valor.replace(/\D/g, ''); // Remove caracteres nÃ£o numÃ©ricos

  if(value.length == 1){
    value = '(' + value.substring(0, 1);
  }else if(value.length == 2){
    value = '(' + value.substring(0, 2);
  }else if(value.length == 3){
    value = '(' + value.substring(0, 2) + ') ' + value.substring(2, 3);
  }else if(value.length >= 4 && value.length <= 7){
    value = '(' + value.substring(0, 2) + ') ' + value.substring(2,7);
  }else if(value.length >= 8 && value.length <= 11 || value.length > 11){
    value = '(' + value.substring(0, 2) + ') ' + value.substring(2,7) + '-' + value.substring(7,11);
  }

  const validacao = (value.length == 15) ? true : false;

  return [value, !validacao];
}

const mascaraCPF = (valor) => {
  let value = valor.replace(/\D/g, ''); // Remove caracteres nÃ£o numÃ©ricos

  if(value.length >= 4 && value.length <= 6){
    value = value.substring(0, 3) + '.' + value.substring(3, 6);
  }else if(value.length >= 7 && value.length <= 9){
    value = value.substring(0, 3) + '.' + value.substring(3, 6) + '.' + value.substring(6, 9);
  }else if(value.length >= 10 && value.length <= 11 || value.length > 11){
    value = value.substring(0, 3) + '.' + value.substring(3, 6) + '.' + value.substring(6, 9) + '-' + value.substring(9, 11);
  }

  const validacao = validaCPF(valor) ? false : true;

  return [value, validacao];
}

const mascaraDataNascimento = (valor) => {
  let value = valor.replace(/\D/g, '');
  
  if(value.length >= 3 && value.length <= 4){
    value = value.substring(0, 2) + ' / ' + value.substring(2, 4);
  }else if(value.length >= 5 && value.length <= 8 || value.length > 8 ){
    value = value.substring(0, 2) + ' / ' + value.substring(2, 4) + ' / ' + value.substring(4, 8);
  }

  let validacao = false;

  if(value.length == 14){
    let data = validaDataNascimento(value);

    if(data !== false){
      validacao = true;
    }
  }

  return [value, !validacao];
}

const validaCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf.length !== 11) return false;

  if (/^(\d)\1+$/.test(cpf)) return false;

  let soma = 0;
  for (let i = 0; i < 9; i++)
    soma += parseInt(cpf.charAt(i)) * (10 - i);

  let resto = 11 - (soma % 11);
  let digito1 = (resto >= 10) ? 0 : resto;

  soma = 0;
  for (let i = 0; i < 10; i++) 
    soma += parseInt(cpf.charAt(i)) * (11 - i);

  resto = 11 - (soma % 11);
  let digito2 = (resto >= 10) ? 0 : resto;

  return (digito1 === parseInt(cpf.charAt(9)) && digito2 === parseInt(cpf.charAt(10)));
}

const validaDataNascimento = (data) => {
  data = data.replace(/\D/g, '');
  let DataObj = new Date(`${data.substring(4, 8)}-${data.substring(2, 4)}-${data.substring(0, 2)}`);
  return isNaN(DataObj.getYear()) ? false : DataObj;
}

export default {
  mascaraTelefone,
  mascaraCPF,
  mascaraDataNascimento,
  validaDataNascimento
}