import { defineStore } from 'pinia'
import { ref } from 'vue'

export const SEvento = defineStore('evento', () => {
  const evento_id = ref(-1);
  const dados_evento = ref({});
  const todos_eventos = ref({});
  const carregando_requisicao = ref(false);

  const carregar_todos_eventos = () => {
    carregando_requisicao.value = true;
    return fetch(`https://eventosiasd.contabil.workers.dev/eventos/lista`, { method : "GET" })
    .then(async(response) => {
      const dados_json = (await response.json());
      todos_eventos.value = dados_json;
      carregando_requisicao.value = false;
      return true;
    })
    .catch(error => {
      console.error(error);
      alert("Ocorreu um erro, tente novamente mais tarde!");
    });
  }

  const carregar_infs = ( indice ) => {
    carregando_requisicao.value = true;
    return fetch(`https://eventosiasd.contabil.workers.dev/evento/dados/${todos_eventos.value[ indice ].token_evento}`, { method : "GET" })
    .then(async(response) => {
      const dados_json = (await response.json());
      dados_evento.value = dados_json;
      carregando_requisicao.value = false;
      return true;
    })
    .catch(error => {
      console.error(error);
      alert("Ocorreu um erro, tente novamente mais tarde!");
    });
  }

  const escolher_evento = (indice) => {
    evento_id.value = indice;
    carregar_infs(indice);
  }

  return {
    evento_id,
    dados_evento,
    todos_eventos,
    carregar_infs,
    carregar_todos_eventos,
    escolher_evento,
    carregando_requisicao
  }
})