<template>
  <CLoading v-if="store_evento.carregando_requisicao" />
  <CTitle v-if="!store_evento.carregando_requisicao" />
  <CBox v-if="!store_evento.carregando_requisicao">
    <CBoxEventos v-if="store_evento.evento_id == -1 && !store_evento.carregando_requisicao" />
    <CBoxFormulario v-if="store_evento.evento_id > -1 && !store_evento.carregando_requisicao" />
  </CBox>
</template>

<script>
  import { SEvento } from '@/stores/SEvento';
  import CTitle from "@/components/CTitle.vue"
  import CBox from "@/components/CBox.vue"
  import CBoxEventos from "@/components/CBoxEventos.vue"
  import CBoxFormulario from "@/components/CBoxFormulario.vue"
  import CLoading from "@/components/CLoading.vue"

  export default {
    name: 'App',
    components: {
      CTitle,
      CBox,
      CBoxEventos,
      CBoxFormulario,
      CLoading
    },
    setup(){
      const store_evento = SEvento();

      store_evento.carregar_todos_eventos();
      
      return {
        store_evento
      }
    }
  }
</script>

<style>
  body{
    padding:0;
    margin:0;
    background-color: rgb(27 68 101);
  }
  #app{
    display:block;
  }
  #app > * {
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  }
</style>